<template>
    <centered-layout>
        <div class="text-center">
            <img
                :src="imageSrc"
                width="200px"
                alt="Sorry"
            >
        </div>
        <h5
            class="fw-light"
            v-html="title"
        />

        <p
            v-show="subTitle"
            v-html="subTitle"
            class="text-muted mb-0"
        />

        <!-- we use anchor tag and button tag for accessibility reasons -->
        <!-- http://web-accessibility.carnegiemuseums.org/content/buttons/ -->
        <div class="d-grid">
            <a
                class="btn btn-secondary mt-3"
                :href="anchorHref"
                v-html="cta"
                v-show="showAnchor"
            />
        </div>
        <div class="d-grid">
            <button
                class="btn btn-secondary mt-3"
                @click="showWebWidget"
                @keydown.enter="showWebWidget"
                v-html="cta"
                v-show="showButton"
            />
        </div>
        <p
            class="text-center text-muted mt-3 mb-0"
            v-html="footer"
            v-show="!!footer"
        />
    </centered-layout>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import { logger } from '@/utils/logger'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { convertDateTimeFormat, getCurrentTimezone } from '@/utils/date'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import CenteredLayout from '@/layouts/Centered'
    import zendeskMixin from '@/mixins/zendeskMixin'

    const reasonsToImgMap = new Map([
        ['authorization', require('@/assets/images/global/sorryidentification.svg')],
        ['renter', require('@/assets/images/global/sorryhome.png')],
        ['homeOwnership', require('@/assets/images/global/sorryhome.png')],
        ['invalidFloodZone', require('@/assets/images/global/sorryhome.png')],
        ['homeIsTrustOrLLC', require('@/assets/images/global/sorryhome.png')],
        ['review', require('@/assets/images/global/human_investigate.svg')],
        ['resourceNotFound', require('@/assets/images/global/human_investigate.svg')],
        ['duplicate', require('@/assets/images/global/human_investigate.svg')],
        ['declinedOffer', require('@/assets/images/global/sadcard.svg')],
        ['authorization', require('@/assets/images/global/padlockshield.svg')],
        ['privateBrowsing', require('@/assets/images/global/lockedapp.svg')],
    ])

    const hideAnchorReasons = ['review', 'default', 'lien', 'privateBrowsing', 'resourceNotFound']

    const showButtonReasons = ['review', 'resourceNotFound']

    export default {
        components: {
            'centered-layout': CenteredLayout,
        },
        mixins: [zendeskMixin],
        data: function () {
            const queryReason = this.$route.query.reason || 'default'
            return {
                queryReason,
                imageSrc: reasonsToImgMap.get(queryReason) || require('@/assets/images/global/sorryicecream.svg'),
                anchorHref: '/support',
                showAnchor: !hideAnchorReasons.includes(queryReason),
                showButton: showButtonReasons.includes(queryReason),
                title: i18n.t(`pages.shared.thanks.reasons.${queryReason}.title`),
                subTitle: '', // computed in mounted
                cta: i18n.te(`pages.shared.thanks.reasons.${queryReason}.cta`) ? i18n.t(`pages.shared.thanks.reasons.${queryReason}.cta`) : i18n.t(`pages.shared.thanks.reasons.default.cta`),
                footer: '', // computed in mounted
            }
        },
        methods: {
            setPageContent: function () {
                const phoneNumber = appSessionStorage.getItem(localStorageKey.phoneNumber) || '?'
                const applicationNumber = appSessionStorage.getItem(localStorageKey.loanApplicationId) || '?'
                const mailErrorSubject = `Regarding the Application Process (Phone #: ${phoneNumber} | Application #: ${applicationNumber})`

                //toString required here for editor highlighting to work correctly
                switch (this.queryReason.toString()) {
                    case 'default':
                    case 'duplicate':
                    case 'identityFailure':
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            link: appSessionStorage.getItem(localStorageKey.startPagePath) || '/',
                            displayEmail: contact_info.support_email,
                            displaySms: contact_info.sms,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    case 'review':
                        this.footer = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.footer`, {
                            displayEmail: contact_info.support_email,
                            displaySms: contact_info.sms,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    case 'authorization':
                        this.anchorHref = appSessionStorage.getItem(localStorageKey.startPagePath) || '/'
                        break
                    case 'expired':
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`)
                        this.anchorHref = appSessionStorage.getItem(localStorageKey.startPagePath) || '/'
                        break
                    case 'tooEarlyToAppointment':
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            dateTime: convertDateTimeFormat(this.$route.query.startTime, 'Etc/UTC', getCurrentTimezone(), 'MMM D [at] h:mm A'),
                            link: this.anchorHref,
                        })
                        break
                    case 'notaryRejection':
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displayEmail: contact_info.support_email,
                            displaySms: contact_info.sms,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    case 'noCameraAccess':
                        this.title = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.title`, {
                            applicantPhone: appSessionStorage.getItem(localStorageKey.phoneNumber) || 'the phone number you gave us',
                        })
                        break
                    case 'ineligibleAppointment':
                        this.anchorHref = appSessionStorage.getItem(localStorageKey.startPagePath) || '/'
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displayEmail: contact_info.support_email,
                            displaySms: contact_info.sms,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    case 'unexpectedErrorOnNotarizationPage':
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displaySms: contact_info.sms,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    case 'declinedOffer':
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displayEmail: contact_info.support_email,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                        })
                        break
                    case 'privateBrowsing':
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displayEmail: contact_info.support_email,
                            displaySms: contact_info.sms,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    case 'closed':
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displayEmail: contact_info.support_email,
                            displaySms: contact_info.sms,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    case 'resourceNotFound':
                        this.footer = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.footer`, {
                            displayEmail: contact_info.support_email,
                            displaySms: contact_info.sms,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    default:
                        this.subTitle = i18n.te(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`) ? i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`) : ''
                }
            },
        },
        mounted: function () {
            if (!i18n.te(`pages.shared.thanks.reasons.${this.queryReason}.title`)) {
                logger.fatal(`Unknown query reason on thanks page: ${this.queryReason}!`)
                window.location.search = ''
                return window.reload()
            }
            this.setPageContent()

            logger.info(`showed sorry page (Reason: ${this.queryReason})`)
            this.$logEvent('view_thanks', { reason: this.queryReason })

            logger.log('Page does not redirect to non-public page, setting clearStorageOnNavigation = true')
            appSessionStorage.setItem(localStorageKey.clearStorageOnNavigation, 'true')
        },
    }
</script>
